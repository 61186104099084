import { DeviceChannelDto, DeviceModelEnum, DeviceParameterDto, DimensionStateEnum, FirmwareEnum } from "src/features/devices/types"

export enum OverheadPowerLineTowerTypeEnum {
    /// Опора
    Tower = 1,
    /// Портал
    Portal = 2,
}

export enum OverheadPowerLineTagEnum
{
    /// Питание с начала
    Begin = 1,
    
    /// Питание с конца
    End = 2,

    /// С двухсторонним питанием
    Double = 3,
}

export enum OverheadLineTypeEnum
{
    /// ВЛ
    OverheadPowerLine = 1,
    
    /// Отпайка
    Decoupling = 2,
}

export type OPL_small = {
    Id: number,
    Name: string,
    Comment: string,
    OverheadPowerLineTag: OverheadPowerLineTagEnum,
    OverhedLineType: OverheadLineTypeEnum,
}

export type OPL_full = {
    Id: 0,
    Name: string,
    Comment: string,
    FirstOverheadPowerLineTowerId: number,
    LastOverheadPowerLineTowerId: number,
    DeletedDate: string,
    OverheadPowerLineTag: OverheadPowerLineTagEnum,
    OverhedLineType: OverheadLineTypeEnum,
    ParentOverheadPowerLineId: number,
    ChildOverheadPowerLines: [
      {
        Id: number,
        Name: string,
        Comment: string,
        OverheadPowerLineTag: OverheadPowerLineTagEnum,
        OverhedLineType: OverheadLineTypeEnum
      }
    ],
    OverheadPowerLineTowers: [
      {
        Id: 0,
        Name: string,
        TowerId: 0,
        Comment: string,
        Latitude: number,
        Longitude: number,
        AutoTimeZoneDetection: boolean,
        AutoTimeZoneSeasonChanging: boolean,
        SummerTimeZoneChanging: Date,
        WinterTimeZoneChanging: Date,
        TimeZone: number
      }
    ]
  }

export type Tower_small = {
  Id: number,
  Name: string,
  TowerId: number,
  Comment: string,
  Latitude:	number | null,
  Longitude:	number | null,
  OverheadPowerLineId: number | null,
}

export enum OverheadPowerLineTowersPhaseEnum {
  A = 1,
  B = 2,
  C = 3,
} 

export type OverheadPowerLineTowerDevicesDto = {
  Id: number,
  Name: string | null,
  Comment:	string | null,
  Latitude: number | null,
  Longitude:	number | null,
  CCID: string | null,
  ICCIDModbus64:	string | null,
  MACModbusHEX:	string | null,
  VersionModbus: number | null,
  IceV2CalculationEnabled:	boolean,
  DeviceModel: DeviceModelEnum,
  AutoTimeZoneDetection:	boolean,
  AutoTimeZoneSeasonChanging:	boolean,
  SummerTimeZoneChanging:	Date | null,
  WinterTimeZoneChanging:	Date | null,
  TimeZone: number | null,
  GetOscilloscope: boolean,
  TimeSynchronization: boolean,
  CompanyId: number | null,
  OverheadPowerLineTowerId: number | null,
  OverheadPowerLineTowersPhase: OverheadPowerLineTowersPhaseEnum,
} 

export type OverheadPowerLineTowerDeviceСhannelsDto = {
  DeviceModelChannelId: number,
  DeviceChannelsDto: (DeviceChannelDto & {DeviceId: number})[],
}

export type OverheadPowerLineTowerDeviceParametersDto = {
  DeviceModelParameterId: number,
  DeviceParametersDto: DeviceParameterDto [] | null,
}

export type Tower_full = {
  Id: number,
  Name:	string | null,
  TowerId:	number | null,
  Comment:	string | null,
  Latitude:	number | null,
  Longitude:	number | null,
  AutoTimeZoneDetection:	boolean,
  AutoTimeZoneSeasonChanging:	boolean,
  SummerTimeZoneChanging:	Date | null,
  WinterTimeZoneChanging:	Date | null,
  TimeZone: number | null,
  DevicesDtos: OverheadPowerLineTowerDevicesDto[] | null,
  OverheadPowerLineTowerDeviceСhannelsDtos: OverheadPowerLineTowerDeviceСhannelsDto[] | null,
  OverheadPowerLineTowerDeviceParametersDtos: OverheadPowerLineTowerDeviceParametersDto [] | null,
  OverheadPowerLineId: number | null,
  CompanyId: number,
}

export type DimensionParameter = {
  Id:                   number,
  DimensionEnabled:     boolean,
  LengthDimension:      number | null,
  Height:               number | null,
  DimensionDate:        Date | null,
  DimensionTime:        Date | null,
  RecalculateDimension: boolean | null,
  HDimensionConst:      number | null,
  DimensionState:       DimensionStateEnum,
}

export type BreakDetectionParameter = {
  Id:                                 number,
  BreakDetectionEnabled:              boolean,
  BreakDetectionMovingAverageWindow:  number | null,
  BreakDetectionAngleDeviation:       number | null,
}

export type FirmwareDTO = {
  Id:         number,
  DeviceId:   number,
  FirmwareId: number,
  Status:     FirmwareEnum,
  StartDate:  Date,
  EndDate:    Date | null,
  PageNumber: number,
  Pages:      number | null,
  Pause:      boolean,
}