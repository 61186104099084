import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

import { OverheadLineTypeEnum, OverheadPowerLineTagEnum, OverheadPowerLineTowerTypeEnum } from "../types";

export type updateOPLCommand = {
    Id:                             number,
    Name:                           string | null,
    Comment:                        string | null,
    FirstOverheadPowerLineTowerId:  number | null,
    LastOverheadPowerLineTowerId:   number | null,
    OverheadPowerLineTag:           OverheadPowerLineTagEnum,
    OverhedLineType:                OverheadLineTypeEnum,
    ParentOverheadPowerLineId:      number | null,
};

export const updateOPL = (command: updateOPLCommand): Promise<EntityId<number>> => {
    return axios.put(`/OverheadPowerLine`, command);
};

type UseUpdateOPLOptions = {
    config?: MutationConfig<typeof updateOPL>;
};

export const useUpdateOPL = (id: number | undefined, { config }: UseUpdateOPLOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries({predicate: x=> ["OPLs", `OPL${id}`].includes(x.queryKey[0] as string)});
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка изменения ВЛ"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({predicate: x=> ["OPLs", `OPL${id}`].includes(x.queryKey[0] as string)});
            showNotification({
                title: t("Успех!"),
                message: t("ВЛ успешно изменена"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: updateOPL,
    });
};
