import { Button, Drawer, Flex, Paper, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RemoteControlCard } from "./RemoteControlCard";
import { OverheadPowerLineTowerDeviceParametersDto, Tower_full } from "../types";
import { useDevices } from "src/features/devices/api/getDevices";
import { useCreateParameter } from "../api/createParameter";

type RemoteControlProps = {
    selectedTower: Tower_full,
};

export const RemoteControl: React.FC<RemoteControlProps> = ({selectedTower}) => {
    const { t } = useTranslation();
    const [opened, { open, close }] = useDisclosure(false);

    const { data: devices} = useDevices()

    type param = {

    }

    const [ params, setParams ] = useState<OverheadPowerLineTowerDeviceParametersDto[]>([])

    useEffect(()=>{
        if (selectedTower && selectedTower?.OverheadPowerLineTowerDeviceParametersDtos){
            setParams(selectedTower.OverheadPowerLineTowerDeviceParametersDtos)
        }
    }, [selectedTower])

    const createParameterMutation = useCreateParameter({id: selectedTower.Id})

    return (
        <>
            {/* <div className="flex justify-end mb-4">
                <Button
                    leftIcon={<IconPlus />}
                    onClick={() => {
                        open();
                    }}
                    variant="outline"
                >
                    {t("Добавить параметр")}
                </Button>
            </div> */}
            <Stack>
                <Flex style={{width: "100%"}} justify="right">
                    <Button leftIcon={<IconPlus/>} onClick={()=>{createParameterMutation.mutateAsync({
                        ParameterId: 
                            (selectedTower.OverheadPowerLineTowerDeviceParametersDtos?.map(x=>x.DeviceModelParameterId) && (selectedTower.OverheadPowerLineTowerDeviceParametersDtos?.length || -1) > 0) ? 
                                Math.max(...selectedTower.OverheadPowerLineTowerDeviceParametersDtos?.map(x=>x.DeviceModelParameterId)) + 1
                            : 0
                            ,
                        OverheadPowerLineTowerId: selectedTower.Id
                    })}}>
                        {t("Добавить параметр")}
                    </Button>
                </Flex>
                {params.map((parameter) => (
                <Stack>
                    <RemoteControlCard towerId={selectedTower.Id} parameter={parameter} />
                </Stack>
                ))}
            </Stack>
            {/* <Drawer
                opened={opened}
                onClose={close}
                title={t("Добавить параметр")}
                padding="xl"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <CreateDeviceParameterForm deviceId={selectedDevice.Id} onClose={close} />
            </Drawer> */}
        </>
    );
};

RemoteControl.displayName = "RemoteControl";
