import { Children, useState } from "react"
import { Flex } from "@mantine/core"
import { IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react"

interface ListCollapserProps {
    children:   React.ReactNode,
    width?:     number,
    enabled?:   boolean,
}

const ListCollapser: React.FC<ListCollapserProps> = ({children, width, enabled}) => {

    const [ opened, setOpened ] = useState(true)

    return(
        enabled ?
        <Flex className="h-full" style={{width: "fit-content", transition: "1s all"}}>
            <Flex style={{
                width: opened ? width?width:400 : 0,
                transition: "0.3s all",
                overflow: "hidden",
            }}>
                {children}
            </Flex>
            <Flex 
                bg={"white"} 
                sx={(theme)=>({
                    zIndex: 10, 
                    padding: "0", 
                    height: "100%", 
                    borderRadius: "0 8px 8px 0", 
                    cursor: "pointer",
                    width: "30px",
                    borderLeft: opened ? `${1}px solid ${
                        theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
                    }` : 'none',
                })} 
                align={"center"} 
                justify={"center"}
                onClick={()=>setOpened(!opened)}
            >
                {opened ? <IconChevronsLeft color="#ADB5BD"/> : <IconChevronsRight color="#ADB5BD"/>}
            </Flex>
        </Flex>
        : <>{children}</>
    )
}

export default ListCollapser