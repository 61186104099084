import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

import { OverheadLineTypeEnum, OverheadPowerLineTagEnum, OverheadPowerLineTowerTypeEnum } from "../types";

export type updateTowerCommand = {
    Id: number,
    Name: string | null,
    TowerId: number | null,
    Comment: string | null,
    Latitude: number | null,
    Longitude: number | null,
    AutoTimeZoneDetection: boolean,
    AutoTimeZoneSeasonChanging: boolean,
    SummerTimeZoneChanging: Date | null,
    WinterTimeZoneChanging: Date | null,
    TimeZone: number | null,
    CompanyId: number | null,
    OverheadPowerLineId: number | null,
  };

export const updateTower = (command: updateTowerCommand): Promise<EntityId<number>> => {
    return axios.put(`/OverheadPowerLineTower`, command);
};

type UseUpdateTowerOptions = {
    config?: MutationConfig<typeof updateTower>;
};

export const useUpdateTower = (id: number | undefined, { config }: UseUpdateTowerOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries({predicate: x=> ["Towers", `Tower${id}`].includes(x.queryKey[0] as string)});
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка изменения опоры"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({predicate: x=> ["Towers", `Tower${id}`].includes(x.queryKey[0] as string)});
            showNotification({
                title: t("Успех!"),
                message: t("Опора успешно изменена"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: updateTower,
    });
};
