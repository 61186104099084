import { Stack } from "@mantine/core";
import React from "react";
import { Tower_full } from "../types";
import { BreakDetection } from "./BreakDetection";
import { Dimension } from "./Dimension";

type SizeProps = {
    tower: Tower_full
}

export const Size: React.FC<SizeProps> = ({tower}) => {
    return(<>
        <Stack>
            <BreakDetection
                towerId={tower.Id}
            />
            <Dimension
                towerId={tower.Id}
            />
        </Stack>
    </>)
}