import { Paper } from "@mantine/core"
import { OPL_full } from "src/features/OPL/types"
import { SearchingList } from "./SearchingList"
import { useTranslation } from "react-i18next"

type CurrentOPLProps = {
    OPL: OPL_full,
    id: number | null,
    setId: (x: number | null) => void,
}

export const CurrentOPL: React.FC<CurrentOPLProps> = ({OPL, id, setId}) => {

    const { t } = useTranslation()

    return(
        <Paper className="mb-4" p="md" shadow="sm" style={{overflowY: "scroll", overflowX: "hidden", marginBottom: 0, boxSizing: "border-box", flexGrow: 1}}>
            <SearchingList
                elements={OPL.OverheadPowerLineTowers}
                label={t("Опоры")}
                setId={setId}
                id={id}
                styles={{width: "100%", height: "fit-content", padding: 0}}
            />
        </Paper>
    )
}