import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { DeviceModelEnum } from "src/features/devices/types";
import { OverheadPowerLineTowersPhaseEnum } from "src/features/OPL/types";

export interface GetCompanyDevicesQuery extends PagedRequest {
    CompanyId: number;
}

export type Device_small = {
    Id:                           number,
    Name:                         string | null,
    Comment:                      string | null,
    CompanyId:                    number | null,
    Latitude:                     number | null,
    Longitude:                    number | null,
    CCID:                         string | null,
    DeviceModel:                  DeviceModelEnum,
    OverheadPowerLineTowerId:     number | null,
    OverheadPowerLineTowersPhase: OverheadPowerLineTowersPhaseEnum,
}

export const getCompanyDevices = (request: GetCompanyDevicesQuery): Promise<PagedCollection<Device_small>> => {
    return axios.post(`/Companies/${request.CompanyId}/devices`, request);
};

type QueryFnType = typeof getCompanyDevices;

type UseCompanyDevicesOptions = {
    request: GetCompanyDevicesQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useCompanyDevices = ({ request, config }: UseCompanyDevicesOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        // queryKey: `company${request.CompanyId}/devices`,
        queryKey: ['CompanyDevices'],
        queryFn: () => getCompanyDevices(request),
    });
};
