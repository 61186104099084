import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type sendDimensionCommand = {
    "OverheadPowerLineTowerId": number,
    "DimensionEnabled":         boolean,
    "LengthDimension":          number | null,
    "Height":                   number | null,
    "DimensionDate":            Date | null,
    "DimensionTime":            Date | null,
  };

export const sendDimension = (command: sendDimensionCommand): Promise<EntityId<number>> => {
    return axios.post(`/api/v1/OverheadPowerLineTower/${command.OverheadPowerLineTowerId}/dimension-parameters`, command);
};

type UseSendDimensionOptions = {
    config?: MutationConfig<typeof sendDimension>;
};

export const useSendDimension = (id: number | null, { config }: UseSendDimensionOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries(`Dimension${id}`);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка задания параметров габарита"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`Dimension${id}`);
            showNotification({
                title: t("Успех!"),
                message: t("Параметры габарита успешно заданы"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: sendDimension,
    });
};
