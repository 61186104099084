import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { FirmwareDTO } from "../types";

export const getFirmware = (id: number | undefined): Promise<FirmwareDTO[]> | undefined => {
    if (id)
        return axios.post(`/OverheadPowerLineTower/${id}/firmware`);
    else
        return undefined
};

type QueryFnType = typeof getFirmware;

export const useFirmware = (id: number | undefined) => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [`FirmwareTower${id}`],
        queryFn: () => getFirmware(id),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                message: t("Ошибка при загрузке прошивок"),
                title: t("Серверная ошибка"),
            });
        },
    });
    
    return result
};
