import { Stack, NumberInput, Switch, Button, Paper } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BreakDetectionParametersDto } from "src/features/devices/types";
import { SchemaOf, object, number } from "yup";
import { boolean } from "yup";
import { sendBreakDetectionCommand, useSendBreakDetection } from "../api/sendBreakDetection";
import { useBreakDetectionParams } from "../api/getBreakDetection";

// const SaveBreakDetectionParametersCommandSchema: SchemaOf<SaveBreakDetectionParametersCommand> = object({
//     DeviceId: number().required(),
//     BreakDetectionEnabled: boolean().required(),
//     BreakDetectionAngleDeviation: number()
//         .transform((value) => (isNaN(value) ? undefined : value))
//         .optional(),
//     BreakDetectionMovingAverageWindow: number()
//         .transform((value) => (isNaN(value) ? undefined : value))
//         .optional(),
// });

type BreakDetectionProps = {
    towerId: number;
};

export const BreakDetection: React.FC<BreakDetectionProps> = ({ towerId }) => {
    const { t } = useTranslation();
    const saveMutation = useSendBreakDetection(towerId);
    const { data: params } = useBreakDetectionParams(towerId ? towerId : undefined)
    const form = useForm<sendBreakDetectionCommand>({
        initialValues: {
        OverheadPowerLineTowerId:             towerId,
        BreakDetectionEnabled:                params && params[0] ? params[0].BreakDetectionEnabled : false,
        BreakDetectionMovingAverageWindow:    params && params[0] ? params[0].BreakDetectionMovingAverageWindow : null,
        BreakDetectionAngleDeviation:         params && params[0] ? params[0].BreakDetectionAngleDeviation : null,
      }});

    useEffect(()=>{
        form.setValues({
            OverheadPowerLineTowerId:             towerId,
            BreakDetectionEnabled:                params && params[0] ? params[0].BreakDetectionEnabled : false,
            BreakDetectionMovingAverageWindow:    params && params[0] ? params[0].BreakDetectionMovingAverageWindow : null,
            BreakDetectionAngleDeviation:         params && params[0] ? params[0].BreakDetectionAngleDeviation : null,
          })
    }, [towerId])

    function handleClose() {
        form.reset();
    }

    return (
        <form
            onSubmit={form.onSubmit(async (values) => {
                await saveMutation.mutateAsync(values);
            })}
        >
            <div className="flex-1 overflow-y-scroll">
                <Stack>
                    <Switch
                        label={t("Обнаружение обрыва")}
                        {...form.getInputProps("BreakDetectionEnabled", { type: "checkbox" })}
                    />
                    <NumberInput
                        disabled={!form.values.BreakDetectionEnabled}
                        required={false}
                        label={t("Размер окна для расчета обрыва")}
                        step={1}
                        {...form.getInputProps("BreakDetectionMovingAverageWindow")}
                    />
                    <NumberInput
                        disabled={!form.values.BreakDetectionEnabled}
                        required={false}
                        label={t("Отклонение угла")}
                        step={1}
                        precision={8}
                        {...form.getInputProps("BreakDetectionAngleDeviation")}
                    />
                </Stack>
                <div className="space-x-3 mt-8 flex justify-end">
                    <Button type="submit">{t("Сохранить")}</Button>
                </div>
            </div>
        </form>
    );
};

BreakDetection.displayName = "BreakDetection";
