import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type CreateChannelCommand = {
    "ChannelId":                number,
    "OverheadPowerLineTowerId": number,
  }

export const createChannel = (command: CreateChannelCommand): Promise<EntityId<number>> => {
    return axios.post(`/overheadpowerlinetowers/${command.OverheadPowerLineTowerId}/overheadpowerlinetower-channels`, command);
};

type UseCreateChannelOptions = {
    id: number,
    config?: MutationConfig<typeof createChannel>;
};

export const useCreateChannel = ({ id, config }: UseCreateChannelOptions) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries(`Tower${id}`);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка создания канала"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`Tower${id}`);
            showNotification({
                title: t("Успех!"),
                message: t("Канал успешно создан"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: createChannel,
    });
};
