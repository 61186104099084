import { Stack, NumberInput, Switch, Button, Paper } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSendDimension, sendDimensionCommand } from "../api/sendDimension";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useDimensionParams } from "../api/getDimensionParams";

type DimensionProps = {
    towerId: number;
};

export const Dimension: React.FC<DimensionProps> = ({ towerId }) => {
    const { t } = useTranslation();
    const saveMutation = useSendDimension(towerId);

    const { data: params } = useDimensionParams(towerId ? towerId : undefined)

    const form = useForm<sendDimensionCommand>({
        initialValues: {
            "OverheadPowerLineTowerId": towerId,
            "DimensionEnabled":         params && params[0] ? params[0].DimensionEnabled : false,
            "LengthDimension":          params && params[0] ? params[0].LengthDimension : null,
            "Height":                   params && params[0] ? params[0].Height : null,
            "DimensionDate":            params && params[0] ? params[0].DimensionDate : null,
            "DimensionTime":            params && params[0] ? params[0].DimensionTime : null,
    }});

    useEffect(()=>{
        form.setValues({
            "OverheadPowerLineTowerId": towerId,
            "DimensionEnabled":         params && params[0] ? params[0].DimensionEnabled : false,
            "LengthDimension":          params && params[0] ? params[0].LengthDimension : null,
            "Height":                   params && params[0] ? params[0].Height : null,
            "DimensionDate":            params && params[0] ? params[0].DimensionDate : null,
            "DimensionTime":            params && params[0] ? params[0].DimensionTime : null,
        })
    }, [towerId])

    function handleClose() {
        form.reset();
    }

    return (
        <form
            onSubmit={form.onSubmit(async (values) => {
                await saveMutation.mutateAsync(values);
            })}
        >
            <div className="flex-1 overflow-y-scroll">
                <Stack>
                    <Switch
                        label={t("Габарит")}
                        {...form.getInputProps("DimensionEnabled", { type: "checkbox" })}
                    />
                    <NumberInput
                        disabled={!form.values.DimensionEnabled}
                        required={false}
                        label={t("Длина")}
                        step={1}
                        {...form.getInputProps("LengthDimension")}
                    />
                    <NumberInput
                        disabled={!form.values.DimensionEnabled}
                        required={false}
                        label={t("Высота")}
                        step={1}
                        precision={8}
                        {...form.getInputProps("Height")}
                    />
                    <DatePicker
                        disabled={!form.values.DimensionEnabled}
                        required={false}
                        label={t("Дата")}
                        {...form.getInputProps("DimensionDate")}
                    />
                    <TimeInput
                        disabled={!form.values.DimensionEnabled}
                        required={false}
                        label={t("Время")}
                        {...form.getInputProps("DimensionTime")}
                    />
                </Stack>
                <div className="space-x-3 mt-8 flex justify-end">
                    <Button type="submit">{t("Сохранить")}</Button>
                </div>
            </div>
        </form>
    );
};