import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type CreateParameterCommand = {
    "ParameterId":              number,
    "OverheadPowerLineTowerId": number,
  }

export const createParameter = (command: CreateParameterCommand): Promise<EntityId<number>> => {
    return axios.post(`/overheadpowerlinetowers/${command.OverheadPowerLineTowerId}/overheadpowerlinetower-parameters`, command);
};

type UseCreateParameterOptions = {
    id: number,
    config?: MutationConfig<typeof createParameter>;
};

export const useCreateParameter = ({ id, config }: UseCreateParameterOptions) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries(`Tower${id}`);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка создания параметра"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`Tower${id}`);
            showNotification({
                title: t("Успех!"),
                message: t("Параметр успешно создан"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: createParameter,
    });
};
