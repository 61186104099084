import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { DeviceModelEnum } from "../types";
import { OverheadPowerLineTowersPhaseEnum } from "src/features/OPL/types";

export type CreateDeviceCommand = {
    Name: string;
    Comment: string;
    Latitude?: number | null;
    Longitude?: number | null;
    CCID: string;
    DeviceModelId: number;
    AutoTimeZoneDetection: boolean;
    AutoTimeZoneSeasonChanging: boolean;
    SummerTimeZoneChanging?: Date | null;
    WinterTimeZoneChanging?: Date | null;
    TimeZone: number | null;
};

export type CreateDeviceCommandNew = {
    Name?:                          string | null,
    Comment?:                       string | null,
    Latitude?:                      number | null,
    Longitude?:                     number | null,
    CCID?:                          string | null,
    DeviceModelId:                  DeviceModelEnum,
    AutoTimeZoneDetection:          boolean,
    AutoTimeZoneSeasonChanging:     boolean,
    SummerTimeZoneChanging?:        Date | null,
    WinterTimeZoneChanging?:        Date | null,
    TimeZone?:                      number | null,
    
    CompanyId?:                     number | null,
    OverheadPowerLineTowerId?:      number | null,
    OverheadPowerLineTowersPhase:   OverheadPowerLineTowersPhaseEnum | null
  }

export const createDevice = (command: CreateDeviceCommandNew): Promise<EntityId<number>> => {
    return axios.post(`/devices`, command);
};

type UseCreateDeviceOptions = {
    CompanyId?: number,
    config?: MutationConfig<typeof createDevice>;
};

export const useCreateDevice = ({ CompanyId, config }: UseCreateDeviceOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            // await queryClient.cancelQueries(["devices", `company${CompanyId}/devices`]);
            await queryClient.cancelQueries(`devices`);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка создания устройства"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            // queryClient.invalidateQueries(["devices", `company${CompanyId}/devices`]);
            queryClient.invalidateQueries(`devices`);
            showNotification({
                title: t("Успех!"),
                message: t("Устройство успешно создано"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: createDevice,
    });
};
