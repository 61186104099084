import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type sendBreakDetectionCommand = {
    "OverheadPowerLineTowerId":             number,
    "BreakDetectionEnabled":                boolean,
    "BreakDetectionMovingAverageWindow":    number | null,
    "BreakDetectionAngleDeviation":         number | null,
  };

export const sendBreakDetection = (command: sendBreakDetectionCommand): Promise<EntityId<number>> => {
    return axios.post(`/api/v1/OverheadPowerLineTower/${command.OverheadPowerLineTowerId}/break-detection`, command);
};

type UseSendBreakDetectionOptions = {
    config?: MutationConfig<typeof sendBreakDetection>;
};

export const useSendBreakDetection = (id: number | null, { config }: UseSendBreakDetectionOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries(`BD${id}`);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка задания параметров обнаружения обрыва"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`BD${id}`);
            showNotification({
                title: t("Успех!"),
                message: t("Параметры обнаружения обрыва успешно заданы"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: sendBreakDetection,
    });
};
