import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/lib/auth";
import { useChangeInfo } from "../api/changeInfo";
import { Stack, TextInput, Button, Textarea } from "@mantine/core";

const InfoForm: React.FC = () => {
    const { t } = useTranslation();

    const { user } = useAuth();

    const mutation = useChangeInfo();

    const [FIO, setFIO] = useState(user?.FIO || "");
    const [login, setLogin] = useState(user?.UserName || "");
    const [email, setEmail] = useState(user?.Email || "");
    const [desc, setDesc] = useState(user?.Description || "");

    const [FIOinvalid, setFIOinvalid] = useState(true);
    const [loginInvalid, setLoginInvalid] = useState(false);
    const [EmailInvalid, setEmailInvalid] = useState(true);

    const regexpFIO = RegExp("^[\\p{L}]+(?:-[\\p{L}]+)?\\s[\\p{L}]+(?:-[\\p{L}]+)?\\s?[\\p{L}]+(?:-[\\p{L}]+)?$", "u");

    useEffect(() => {
        setFIOinvalid(!regexpFIO.test(FIO) || FIO.length === 0);
    }, [FIO]);

    const regexpLogin = RegExp("^[\\p{L}0-9._-]+$", "u");
    useEffect(() => {
        setLoginInvalid(!regexpLogin.test(login) || login.length === 0);
    }, [login]);

    const regexpEmail = RegExp("^[\\p{L}0-9._%+-]+@[\\p{L}0-9.-]+\\.[a-zA-Z]{2,}$", "u");
    useEffect(() => {
        setEmailInvalid(!regexpEmail.test(email) || email.length === 0);
    }, [email]);

    return (
        <Stack>
            <TextInput
                label={t("ФИО")}
                placeholder={t("Фамилия Имя Отчество")}
                value={FIO}
                onChange={(event) => setFIO(event.currentTarget.value)}
                error={FIOinvalid && FIO.length > 0 ? t("Введите валидное ФИО") : false}
                withAsterisk
            />

            <TextInput
                label={t("Логин")}
                placeholder={"Username"}
                value={login}
                onChange={(event) => setLogin(event.currentTarget.value)}
                error={loginInvalid && login.length > 0 ? t("Введите валидный логин") : false}
                withAsterisk
            />

            <TextInput
                label={"e-mail"}
                placeholder={"example@xmpl.ex"}
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
                error={EmailInvalid && email.length > 0 ? t("Введите валидный e-mail") : false}
                withAsterisk
            />

            <Textarea
                label={t("Описание")}
                placeholder={"Введите описание"}
                value={desc}
                onChange={(event) => setDesc(event.currentTarget.value)}
            />

            <Button
                styles={{
                    root: {
                        borderRadius: "8px",
                        width: "fit-content",
                        margin: "auto",
                    },
                }}
                disabled={FIOinvalid || EmailInvalid || loginInvalid}
                onClick={() =>
                    mutation.mutateAsync({
                        Id: user?.Id,
                        UserName: login,
                        FIO: FIO,
                        Description: desc,
                        Email: email,
                    })
                }
            >
                {t("Сохранить")}
            </Button>
        </Stack>
    );
};

export default InfoForm;
