import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { BreakDetectionParameter } from "../types";

export const getBreakDetectionParams = (id: number | undefined): Promise<BreakDetectionParameter[]> | undefined => {
    if (id)
        return axios.get(`/OverheadPowerLineTower/${id}/break-detection`);
    else
        return undefined
};

type QueryFnType = typeof getBreakDetectionParams;

export const useBreakDetectionParams = (id: number | undefined) => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [`BD${id}`],
        queryFn: () => getBreakDetectionParams(id),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                message: t("Ошибка при загрузке параметров обрыва"),
                title: t("Серверная ошибка"),
            });
        },
    });
    
    return result
};
