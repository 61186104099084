import { useMutation } from "react-query";

import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { OverheadPowerLineTowersPhaseEnum } from "src/features/OPL/types";

export type UpdateDeviceCommand = {
    Id: number;
    Name: string;
    Comment: string;
    Latitude?: number | null;
    Longitude?: number | null;
    DeviceModelId: number;
    AutoTimeZoneDetection: boolean;
    AutoTimeZoneSeasonChanging: boolean;
    SummerTimeZoneChanging?: Date | null;
    WinterTimeZoneChanging?: Date | null;
    TimeZone?: number | null;
    CompanyId?: number | null;
    OverheadPowerLineTowerId?: number | null;
    OverheadPowerLineTowersPhase?: OverheadPowerLineTowersPhaseEnum | null;
};

export const updateDevice = (command: UpdateDeviceCommand): Promise<EntityId<number>> => {
    return axios.put(`/devices`, command);
};

type UseUpdateDeviceOptions = {
    id?:        number,
    config?:    MutationConfig<typeof updateDevice>;
};

export const useUpdateDevice = ({ id, config }: UseUpdateDeviceOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries({predicate: x=> [`devices`, `CompanyDevices`, `device${id}`].includes(x.queryKey[0] as string)});
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка обновления устройства!"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({predicate: x=> [`devices`, `CompanyDevices`, `device${id}`].includes(x.queryKey[0] as string)});
            showNotification({
                title: t("Успех!"),
                message: t("Успешно обновлена информация об устройстве!"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: updateDevice,
    });
};
