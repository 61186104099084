import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

import { OverheadLineTypeEnum, OverheadPowerLineTagEnum, OverheadPowerLineTowerTypeEnum } from "../types";

export type sendParameterCommand = {
    "OverheadPowerLineTowerId":     number,
    "ParameterId":                  number,
    "Value":                        string
  };

export const sendParameter = (command: sendParameterCommand): Promise<EntityId<number>> => {
    return axios.post(`/overheadpowerlinetowers/${command.OverheadPowerLineTowerId}/overheadpowerlinetower-parameters/send`, {
        "OverheadPowerLineTowerId":     command.OverheadPowerLineTowerId,
        "ParameterId":                  command.ParameterId,
        "Value":                        command.Value
    });
};

type UseSendParameterOptions = {
    config?: MutationConfig<typeof sendParameter>;
};

export const useSendParameter = (id: number | null, { config }: UseSendParameterOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries(`Tower${id}`);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка изменения параметра"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`Tower${id}`);
            showNotification({
                title: t("Успех!"),
                message: t("Параметр успешно задан"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: sendParameter,
    });
};
