import React, { useEffect, useState } from "react";
import { useAuth } from "src/lib/auth";

import CompaniesHead from "src/features/companies/components/CompaniesHead";
import CompaniesList from "src/features/companies/components/CompaniesList";
import CurrentCompany from "src/features/companies/components/CurrentCompany";
import CompanyEditor from "src/features/companies/components/CompanyEditor";

import { CompanyT } from "src/features/companies/api/getCompanies";
import { useCompany } from "src/features/companies/api/getCompany";
import { RoleEnum } from "src/features/auth";
import { UserT } from "src/features/users_new/api/getUsers";

const CompaniesView: React.FC = () => {

    const [tab,     setTab               ] = useState                 ("OP")
    const [company, setCompany           ] = useState<CompanyT | null>(null     )
    const [path,    setPath              ] = useState<CompanyT[]     >([]     )
    const [editorOpened , setEditorOpened] = useState                 (false)
    const [movingUser, setMovingUser     ] = useState<UserT | null>   (null)

    const { user } = useAuth()

    const {data: userCompany} = useCompany(user?.CompanyId || null)

    useEffect(()=>{
        let tmp = JSON.parse(JSON.stringify(path))
        if(company && path.length !== 0 && path[path.length-1] && path[path.length-1].Id === company.CompanyParentId){
            tmp.push(company)
            setPath(tmp)
        }
        else if(company && path.length !== 0 && path[path.length-1] && path[path.length-1].CompanyParentId === company.Id){
            tmp.pop()
            setPath(tmp)
        }
        else if(company && path.length !== 0 && path[path.length-1] && path[path.length-1].CompanyParentId === company.CompanyParentId){
            tmp.pop()
            tmp.push(company)
            setPath(tmp)
        }
        else {
            tmp = []
            tmp.push(company)
            setPath(tmp)
        }
    }, [company])

    useEffect(()=>{
        setCompany(userCompany ? userCompany : null)
    }, [userCompany])

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100vh",
            }}
        >
            {user?.Role !== RoleEnum.User ?
                <CompaniesList
                    depth                   = {path.length} 
                    select_company          = {setCompany}
                    current_company         = {company}
                    path                    = {path}
                    setPath                 = {setPath}
                    userCompany             = {userCompany?userCompany:null}
                />
            : <div></div>}
            <div
                className="p-4"
                style={{
                    width: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                }}
            >
                <CompaniesHead
                    tab        = {tab}
                    select_tab = {setTab}
                    company    = {company}
                    path       = {path}
                    openEditor = {()=>setEditorOpened(true)}
                />
                <CurrentCompany
                    company         =   {company}
                    tab             =   {tab}  
                    select_company  =   {setCompany}
                    movingUser      =   {movingUser}
                    setMovingUser   =   {setMovingUser}
                />
            </div>
            <CompanyEditor
                opened  = {editorOpened}
                close   = {()=>setEditorOpened(false)}
                open    = {()=>setEditorOpened(true)}
                company = {company}
            />
        </div>
    );
};

export default CompaniesView