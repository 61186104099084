import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type FirmwareDeviceCommand = {
    FirmwareId?: number | null;
    DeviceId?: number | null;
};

export const firmwareDevices = (commands: FirmwareDeviceCommand[]) => {
    return Promise.all(commands.map(command=>axios.post(`/firmware/${command.FirmwareId}/devices/${command.DeviceId}/start`, command)));
};

type UseFirmwareDevicesOptions = {
    id: number,
    config?: MutationConfig<typeof firmwareDevices>;
};

export const useFirmwareDevices = ({ id, config }: UseFirmwareDevicesOptions) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries(`FirmwareTower${id}`);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка запроса на перепрошивку"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`FirmwareTower${id}`);
            showNotification({
                title: t("Успех!"),
                message: t("Запрос на перепрошивку получен"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: firmwareDevices,
    });
};
