import React, { useRef, useEffect, useState } from "react";
import { useYMaps } from "@pbe/react-yandex-maps";
import YandexMap from "../../components/Map/YandexMap";
import PositionMarker from "../Map/PositionMarker";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { DeviceDto } from "src/features/devices/types";
import { useDevicesStore } from "src/features/devices/store";

const MapContainer = styled.div({
    ...tw`h-full absolute left-0 right-0 flex-1`,
});

interface MapComponentProps {
    devices?: DeviceDto[];
}

let devicesMarkers: PositionMarker[] = [];

const MapComponent: React.FC<MapComponentProps> = ({ devices }) => {
    const { selectDevice } = useDevicesStore();
    const ymap = useRef<YandexMap>();
    const ymaps = useYMaps();

    const [ userPosition, setUserPosition ] = useState<number[]>([])    

    const get_user_position = () => {
        navigator.geolocation.getCurrentPosition(position => setUserPosition([position.coords.latitude, position.coords.longitude]))
    }

    useEffect(()=>{
        get_user_position()
    }, [navigator])

    useEffect(() => {
        if (ymaps && ymaps.Map) {
            ymap.current = new YandexMap(ymaps, "ymap", {
                center: userPosition.length !== 0 ? userPosition : [54.194442, 37.599728],
                zoom: 10,
                maxZoom: 18,
            });
        }

        return () => {
            if (ymap.current) {
                ymap.current.destroy();
            }
        };
    }, [ymaps]);

    useEffect(() => {
        devicesMarkers.forEach((x) => x.hide());
        const markers: PositionMarker[] = [];
        devices?.forEach((x) => {
            if (ymap.current && x.Latitude && x.Longitude) {
                const marker = new PositionMarker(ymap.current, x, () => {
                    selectDevice(x);
                });
                marker.show();
                markers.push(marker);
            }
        });
        devicesMarkers = markers;

        return () => {
            devicesMarkers.forEach((x) => x.hide());
        };
    }, [devices, ymaps, selectDevice]);

    return <MapContainer id="ymap" />;
};

export default MapComponent;
