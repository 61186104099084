import { Badge, Button, Card, Group, LoadingOverlay, NumberInput, Select, Stack, Text, Title, Tooltip } from "@mantine/core";
import { useForm } from "@mantine/form";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useServerUrls } from "src/features/devices/api/getServerUrls";
import { useSendParameter } from "../api/sendParameter";
import { ParameterEnum, ParameterState } from "src/features/devices/types";
import { OverheadPowerLineTowerDeviceParametersDto } from "../types";

const stateLabels = {
    [ParameterState.None]: "Не отправлялось",
    [ParameterState.Queued]: "В очереди",
    [ParameterState.Sent]: "Отправлено",
    [ParameterState.SentWithError]: "Ошибка при отправке",
    [ParameterState.CancelledByTTL]: "Превышено время ожидания",
};

export enum InputType {
    None,
    Text,
    Number,
}

const parametersControlSettings = {
    [ParameterEnum.ShortCircuitCurrentSetting]: {
        inputType: InputType.Number,
        defaultValue: "10",
    },
    [ParameterEnum.ShortCircuitPulseTimeSetting]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.MinimumCurrentSetting]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.ServerHost]: {
        inputType: InputType.Text,
        defaultValue: "",
    },
    [ParameterEnum.Restart]: {
        inputType: InputType.None,
        defaultValue: "1",
    },
    [ParameterEnum.CommunicationPeriod]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.ShortCircuitCurrentSetting2]: {
        inputType: InputType.Number,
        defaultValue: "10",
    },
    [ParameterEnum.ShortCircuitPulseTimeSetting2]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.MinimumCurrentSetting2]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.CommunicationPeriod2]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.Restart2]: {
        inputType: InputType.None,
        defaultValue: "1",
    },
    [ParameterEnum.ServerHost2]: {
        inputType: InputType.Text,
        defaultValue: "",
    },
    [ParameterEnum.MaxOperTime]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.DurationPeriod]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.OscilloscopeDuration]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.FirstSetpoint]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.SecondSetpoint]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.ThirdSetpoint]: {
        inputType: InputType.Number,
        defaultValue: "1",
    },
    [ParameterEnum.Invert]: {
        inputType: InputType.Number,
        defaultValue: "0",
    },
};

type RemoteControlCardProps = {
    towerId:       number;
    parameter:      OverheadPowerLineTowerDeviceParametersDto;
};

export const RemoteControlCard: React.FC<RemoteControlCardProps> = ({ towerId, parameter }) => {
    const { t } = useTranslation();

    const { data: serverUrls, isLoading } = useServerUrls();
    // const { validateAsync } = useParameterValueValidate({ deviceParameter: parameter });

    const sendParameterMutation = useSendParameter(towerId)
    const form = useForm({
        initialValues: {
            OverheadPowerLineTowerId: towerId,
            ParameterId: (parameter.DeviceParametersDto && parameter.DeviceParametersDto[0]) ? parameter.DeviceParametersDto[0].ParameterId : 0 ,
            Value: ((parameter.DeviceParametersDto && parameter.DeviceParametersDto[0]) ? parameter.DeviceParametersDto[0].Value : null) ?? 
                    parametersControlSettings[(parameter.DeviceParametersDto && parameter.DeviceParametersDto[0]) ? parameter.DeviceParametersDto[0].ParameterId as ParameterEnum : 1 as ParameterEnum].defaultValue,
        },
    });

    useEffect(()=>{
        form.setValues({
            OverheadPowerLineTowerId: towerId,
            ParameterId: (parameter.DeviceParametersDto && parameter.DeviceParametersDto[0]) ? parameter.DeviceParametersDto[0].ParameterId : 0 ,
            Value: (parameter.DeviceParametersDto && parameter.DeviceParametersDto[0] ? parameter.DeviceParametersDto[0].Value : null) ?? 
                    parametersControlSettings[(parameter.DeviceParametersDto && parameter.DeviceParametersDto[0]) ? parameter.DeviceParametersDto[0].ParameterId as ParameterEnum : 1 as ParameterEnum].defaultValue,
        })
    }, [parameter])

    if (isLoading) {
        return <LoadingOverlay visible={true} />;
    }

    const form_state = (states: ParameterState[]) => {
        if (states.reduce((homogeneous, state) => (states.filter(x=>x === state).length!==1) && homogeneous, true))
            return states[0]
        let kols = {
            [ParameterState.None]: 0,
            [ParameterState.Queued]: 0,
            [ParameterState.Sent]: 0,
            [ParameterState.SentWithError]: 0,
            [ParameterState.CancelledByTTL]: 0,
        }
        const arrNumbers = Object.keys(kols).map(key => kols[key as unknown as ParameterState]);
        const maxValue = Math.max(...arrNumbers);

        const key = Object.keys(kols).find(key => kols[key as unknown as ParameterState] === maxValue) || states[0]
        return key as unknown as ParameterState
    }

    const form_date = (dates: (string | null)[]) => {
        let presents_not_null = false
        let valid_dates: Dayjs[] = []

        for (let date of dates){
            presents_not_null = presents_not_null || (date !== null)
            if (date !== null)
                valid_dates.push(dayjs(date))
        }
        if (!presents_not_null)
            return t('Дата последней отправки не определена') 
        valid_dates = valid_dates.sort((b, a) => a.valueOf() - b.valueOf())
        return valid_dates.map(x=>x.format("DD.MM.YYYY HH:mm"))
    }

    return (
        (parameter && parameter.DeviceParametersDto && parameter.DeviceParametersDto[0]) ?
        <Card
            withBorder
            component="form"
            onSubmit={form.onSubmit(async (values) => {
                await sendParameterMutation.mutateAsync({
                    ...values,
                });
            })}
            // onSubmit={()=>{}}
        >
            <Group position="apart">
                <Stack
                    sx={{
                        width: "fit-content",
                        maxWidth: "50%",
                    }}
                >
                    <Title order={4}>{t(parameter && parameter.DeviceParametersDto && parameter.DeviceParametersDto[0] ? parameter.DeviceParametersDto[0].Name : '').toString()}</Title>
                    <Group position="apart" style={{width: 'fit-content'}}>
                        <Group>
                            {parameter.DeviceParametersDto ?
                            <Tooltip label={
                                    parameter.DeviceParametersDto.map(param=>` ${param.DeviceId}: ${stateLabels[param.State]}    `)
                            }>
                                <Badge radius="xs" size="sm" color="dark" variant="filled">
                                    {t(stateLabels[form_state(parameter.DeviceParametersDto?.map(x=>x.State))]).toString()}
                                </Badge>
                            </Tooltip>
                            : ''}
                        </Group>
                        <Group>
                            {parameter.DeviceParametersDto ?
                            <Tooltip label={
                                parameter.DeviceParametersDto.map(x=>`${x.DeviceId}: ${x.Timestamp ? x.Timestamp : t('не определено')} `)
                            }>
                                    <Text size="sm" color="gray" style={{width: 'fit-content'}}>
                                        {t("Дата последней отправки").toString()}:{" "}
                                        {Array.isArray(form_date(parameter.DeviceParametersDto.map(x=>x.Timestamp))) ? form_date(parameter.DeviceParametersDto.map(x=>x.Timestamp))[0] : form_date(parameter.DeviceParametersDto.map(x=>x.Timestamp))}
                                    </Text>
                                </Tooltip>
                            : ''}
                        </Group>
                    </Group>
                </Stack>
                <Group>
                    {(parameter && parameter.DeviceParametersDto && parameter.DeviceParametersDto[0]) ?
                    parametersControlSettings[parameter.DeviceParametersDto[0].ParameterId as ParameterEnum].inputType === InputType.Text && (
                        <Select
                            disabled={parameter.DeviceParametersDto[0].Disabled}
                            withinPortal={true}
                            data={serverUrls?.map((x) => x.Url) || []}
                            {...form.getInputProps("Value")}
                        />
                    ) : ''}
                    {parameter && parameter.DeviceParametersDto ?
                    parametersControlSettings[parameter.DeviceParametersDto[0].ParameterId as ParameterEnum].inputType ===
                        InputType.Number && (
                        <NumberInput
                            disabled={parameter.DeviceParametersDto[0].Disabled}
                            max={Math.min(...parameter.DeviceParametersDto.map(x=>(x.MaxValue !== null) ? x.MaxValue : -100500) as unknown as number[])!}
                            min={Math.max(...parameter.DeviceParametersDto.map(x=>(x.MinValue !== null) ? x.MinValue :  100500) as unknown as number[])!}
                            value={Number(form.getInputProps("Value").value)}
                            onChange={(value) => form.getInputProps("Value").onChange(value)}
                        />
                    ) :''}
                    <Button 
                        disabled={parameter.DeviceParametersDto?.reduce((acc, x)=>acc = acc || x.State === ParameterState.Queued, false) || parameter.DeviceParametersDto?.reduce((acc, x)=>acc = acc || x.Disabled, false)} 
                        type="submit"
                    >
                        {t("Отправить").toString()}
                    </Button>
                </Group>
            </Group>
        </Card>
        :<></>
    );
};

RemoteControlCard.displayName = "RemoteControlCard";
